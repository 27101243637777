import { Box, IconButton, Typography } from "@mui/material";
import {GridView, List} from '@mui/icons-material';
import { useEffect, useState } from "react";
import config from "../config";
import { companyData } from "../data/data";
import { Company, GoodOrService, GoodsConfig } from "../types";
import Filter from "./Filter";
import Results from "./Results";
import Search from "./Search";
import isEqual from 'lodash.isequal';

export default function Content() {
    const [goodOrService, setGoodOrService] = useState<GoodOrService>('both');
    const [typeOfGoods, setTypeOfGoods] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [wayToViewData, setWayToViewData] = useState<'grid' | 'list'>('grid');
    const [companies, setCompanies] = useState<Company[]>(companyData);

    useEffect(() => {
        function filterCompanyDataBySearchString(listToFilter: Company[]): Company[] {
            return searchValue.length > 0 ? listToFilter.filter(company => company.company?.toLowerCase().includes(searchValue.toLowerCase()) || company.name?.toLowerCase().includes(searchValue.toLowerCase()) || company.location?.toLowerCase().includes(searchValue.toLowerCase())) : listToFilter;
        }

        const goodOrServiceList = goodOrService === 'both' ? ['goods', 'service'] : [goodOrService];
        const typeOfGoodsList = typeOfGoods === '' ? [...config.goods.map(good => good.name.toLowerCase())] : [typeOfGoods.toLowerCase()];
        console.log('goodOrServiceList', goodOrServiceList);
        console.log('typeOfGoodsList', typeOfGoodsList);
        console.log('searchValue', searchValue);
        const newValues = goodOrService === 'both' && typeOfGoods === '' 
                ? filterCompanyDataBySearchString(companyData) 
                : filterCompanyDataBySearchString(companyData.filter(data => data.goods_services && data.product_service && goodOrServiceList.includes(data.goods_services.toLowerCase()) && typeOfGoodsList.includes(data.product_service?.toLowerCase())));
        if (!isEqual(companies, newValues)) {
            setCompanies(newValues);
        }
    }, [goodOrService, typeOfGoods, searchValue]);

    return (
        <Box display='flex' flexDirection='column' alignItems='flex-start' padding={5}>
            <Search searchValue={searchValue} setSearchValue={(newValue: string) => setSearchValue(newValue)} />
            <Filter 
                goodOrService={goodOrService}
                typeOfGoods={typeOfGoods}
                setGoodOrService={(goodServiceFilter: GoodOrService) => setGoodOrService(goodServiceFilter)} 
                setTypeOfGoods={(typeOfGoodsFilter: string) => setTypeOfGoods(typeOfGoodsFilter)} 
            />
            <Box mt={4} display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                <Typography variant="h5" borderBottom='1px solid #444'>Companies</Typography>
                <Box ml='auto'>
                    <IconButton color={wayToViewData === 'grid' ? 'primary' : 'inherit'} onClick={() => setWayToViewData('grid')}>
                        <GridView />
                    </IconButton>
                    <IconButton color={wayToViewData === 'list' ? 'primary' : 'inherit'} onClick={() => setWayToViewData('list')}> 
                        <List />
                    </IconButton>
                </Box> 
            </Box>
            <Box width='100%'>
                {companies ? (
                    <Results companies={companies} wayToViewData={wayToViewData} />
                ) : null}
            </Box>
        </Box>
    )
}