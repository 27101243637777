import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import {Menu, MenuItem, ListItemIcon, Divider, Typography, Button} from '@mui/material';
import { Link } from 'react-router-dom';
import PlainLink from './PlainLink';


export default function Navbar() {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between', height: '75px', px: 5 }}>
            <PlainLink link='/'>
                <Typography>Local Something</Typography>
            </PlainLink>
            <Typography sx={{ minWidth: 100, padding: 3, textTransform: 'uppercase' }}>Company Page</Typography>
            <Button variant='contained' color='primary'>Contact Us</Button>
        </Box>
    )
}