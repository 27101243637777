import { companyData } from "../data/data";

function getCompany(id: number) {
    console.log('id', id);
    console.log('companyData.find(comp => comp.id === id)', companyData.find(comp => comp.id === id));
    console.log('companyData', companyData);
    return companyData.find(comp => comp.id === id) || null;
}

export async function companyLoader({params}: any) {
    return getCompany(Number(params.companyId));
}