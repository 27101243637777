import { Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
    link: string;
    text?: string;
    children: ReactNode;
}

export default function PlainLink({children, link, text}: Props) {
    return (
        <Link to={link} style={{textDecoration: 'none', color: 'inherit'}}>
            {children}
        </Link>
    )
}