export const companyData = [
	{
		"id": 1,
		"name": "Justin Viens",
		"company": "On A Limb Apparel",
		"product_service": "Apparel",
		"goods_services": "Goods",
		"location": "Denver, CO",
		"primary_means_of_sales": "In Person + Online",
		"website": "onalimbapparel.com",
		"instagram": "https://www.instagram.com/onalimbapparel/",
		"instagram_tag": "onalimbapparel",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 2,
		"name": "Nikki Arvidson ",
		"company": "Who Let The Dogs Out? (Name pending trying to figure out if this is dumb)",
		"product_service": "Dog sitting",
		"goods_services": "Service",
		"location": "Denver ",
		"primary_means_of_sales": "WoM",
		"website": null,
		"instagram": null,
		"instagram_tag": "",
		"active": null,
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 3,
		"name": "Nikki Arvidson ",
		"company": "Carrot Camera Photography",
		"product_service": "Portrait photography ",
		"goods_services": "Service",
		"location": "Denver ",
		"primary_means_of_sales": "WoM",
		"website": null,
		"instagram": "https://www.instagram.com/thecarrot_camera/",
		"instagram_tag": "thecarrot_camera",
		"active": null,
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 4,
		"name": "David Gum",
		"company": "Enhanced Perception Studios",
		"product_service": "Videography",
		"goods_services": "Service",
		"location": "Colorado Springs, CO",
		"primary_means_of_sales": "Word of Mouth",
		"website": "https://www.enhancedperceptionstudios.com/",
		"instagram": "https://www.instagram.com/inspirenarrative/",
		"instagram_tag": "inspirenarrative",
		"active": "?",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 5,
		"name": "Meryl ",
		"company": "mindfulpots",
		"product_service": "Unique Pottery",
		"goods_services": "Goods",
		"location": "Charlottesville, VA",
		"primary_means_of_sales": "Instagram",
		"website": null,
		"instagram": "https://www.instagram.com/mindfulpots/",
		"instagram_tag": "mindfulpots",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 6,
		"name": "Bobby Harrington",
		"company": "Dryfter",
		"product_service": "Home Vacation Rental",
		"goods_services": "Service",
		"location": "Vermont",
		"primary_means_of_sales": "Word of Mouth",
		"website": "dryfterhomes.com",
		"instagram": "https://www.instagram.com/dryfterhomes/",
		"instagram_tag": "dryfterhomes",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "OFFICE-EQUIPPED RENTAL PROPERTIES FOR REMOTE WORKERS",
		"description": "Dryfter provides stylish, comfortable, private homes fully-equipped for digital nomads in amazing locations. Spend time working remotely with your friends or rent the place for you and your family. Working remotely has never been this comfortable.\r"
	},
	{
		"id": 7,
		"name": "Lisa + Stewart Harrison",
		"company": "Sojourn Well ",
		"product_service": "Jewelry",
		"goods_services": "Goods",
		"location": "Colorado",
		"primary_means_of_sales": "Web, Instagram, Store",
		"website": "https://www.etsy.com/shop/SojournWell",
		"instagram": "https://www.instagram.com/sojournwelljewelry/",
		"instagram_tag": "sojournwelljewelry",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Jewelry made by us, Stuart & Lisa, in our tiny cabin in the woods of Colorado",
		"description": "It all began in 2011. At that time, the business was just me (Lisa) fresh out of high school, and Stuart was just the boy who lived across the street. Creating and selling my artwork to fund raise for a mission trip was what made me realize what a passion I had for not only creating, but also for business. I knew I wanted to continue, so I started Sojourn Well. The company grew as I went to school for a degree in business. When I graduated, I decided to skip the desk job and make jewelry full time. Fast forward to today: Stuart and I got married and we now make jewelry full time together! In 2019 we built a custom studio in a camper, packed up our life in rural Virginia, and hit the road. After 1.5 years of full time RV boondocking and exploring to find the place we wanted to call home, we finally found just the right spot in little Woodland Park, CO. So far it’s been a dream! Thank you so much for helping to make it possible by supporting our small handmade business.\r"
	},
	{
		"id": 8,
		"name": "Christopher Privitera",
		"company": "Good Vibrations Beach Rides",
		"product_service": "Dune Buggy Rentals",
		"goods_services": "Service",
		"location": "Outer Banks, North Carolina",
		"primary_means_of_sales": "Web, Instagram",
		"website": "http://outerbankdunebuggyrentals.com/",
		"instagram": "https://www.instagram.com/good_vibrations_obx/",
		"instagram_tag": "good_vibrations_obx",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Good Vibrations Beach Rides is the Outer Banks High-Performance Dune Buggy Rental Outfitter",
		"description": "We aren’t just another Outer Banks car rental agency, our custom dune buggies offer a one-of-a-kind experience!\r ALL OF OUR VEHICLES ARE 4-SPEED MANUAL TRANSMISSIONS, and with two-seater and four-seater options, there’s sure to be a beach ride perfect for your group. You’ve never experienced the beach quite like this! Contact us today to set up your dune buggy adventure and experience the Good Vibes for yourself!\r"
	},
	{
		"id": 9,
		"name": "Hugh Mortin",
		"company": "Tensile Payments",
		"product_service": "Payment Platform",
		"goods_services": "Service",
		"location": "New York",
		"primary_means_of_sales": "Word of Mouth",
		"website": "https://www.tensilepayments.com/",
		"instagram": null,
		"instagram_tag": "",
		"active": null,
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 10,
		"name": "Luke Chapman",
		"company": "Startfromskratch",
		"product_service": "Music Producer",
		"goods_services": "Service",
		"location": "San Diego, California",
		"primary_means_of_sales": "Web, Instagram",
		"website": null,
		"instagram": "https://www.instagram.com/startfromskratch/",
		"instagram_tag": "startfromskratch",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": "Spotify",
		"other_link_2": "Soundcloud",
		"other_link_3": "Youtube",
		"short_description": "StartFromSKratch is an up-and-coming producer from Chicago, IL.",
		"description": "After the deaths of close friends and his own personal battle with depression, he turned to music to share his experiences and to find peace of mind. His music is heavily influenced by R&B, EDM, Dance, and house genres-elements of each shape his sound. He currently resides in San Diego, CA.\r"
	},
	{
		"id": 11,
		"name": "Myles Becker",
		"company": "Seussmans T Shirts",
		"product_service": "Apparel",
		"goods_services": "Goods",
		"location": "Denver, CO",
		"primary_means_of_sales": "Web, Instagram",
		"website": "https://seussman71.threadless.com/designs/",
		"instagram": "https://www.instagram.com/seussman71/",
		"instagram_tag": "seussman71",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 12,
		"name": "Liam Schenking",
		"company": "RVA Creates",
		"product_service": "Custom Stained Glass / other",
		"goods_services": "Goods",
		"location": "Richmond, VA",
		"primary_means_of_sales": "Web, Instagram",
		"website": "https://www.etsy.com/shop/rvaglassstore/",
		"instagram": "https://www.instagram.com/rva_creates/",
		"instagram_tag": "rva_creates",
		"active": "?",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 13,
		"name": "Malcolm Stewart",
		"company": "Moments by Malc",
		"product_service": "Photography + Videography",
		"goods_services": "Service",
		"location": "USA",
		"primary_means_of_sales": "Word of Mouth",
		"website": "https://www.momentsbymalc.com/",
		"instagram": "https://www.instagram.com/momentsbymalc/",
		"instagram_tag": "momentsbymalc",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Wedding Photographer & Videographer based in Charlottesville, Virginia",
		"description": "Every time I pick up a camera, I know there is a chance to capture something special. Whether that is special to me, special to someone else, or special in history. These little moments in time that we never get back, never can recreate, but always want to remember are the moments I live for, and the ones I love to capture.\r"
	},
	{
		"id": 14,
		"name": "Brooks Hurbis",
		"company": "Sweat Simple",
		"product_service": "Fitness Coach",
		"goods_services": "Service",
		"location": "Denver, CO",
		"primary_means_of_sales": "Word of Mouth",
		"website": "https://www.sweatsimpleco.com/",
		"instagram": "https://www.instagram.com/sweat.simple/",
		"instagram_tag": "sweat.simple",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "The Simple Guide to a Balanced Lifestyle",
		"description": "Hi! Im Brooks, creator of SweatSimple. I have a passion for making health and wellness a sustainable lifestyle and believe in creating a balance between healthy eating, exercise and everyday life. I want to inspire YOU that fitness & nutrition can be simple and maintainable with any lifestyle.\r"
	},
	{
		"id": 15,
		"name": "Andy Norwood",
		"company": "Spicy Mustard Brand",
		"product_service": "Apparel",
		"goods_services": "Goods",
		"location": "Evergreen, CO",
		"primary_means_of_sales": "Web, Instagram",
		"website": "https://thespicymustard.com/",
		"instagram": "https://www.instagram.com/thespicymustardbrand/",
		"instagram_tag": "thespicymustardbrand",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 16,
		"name": "Will Haack",
		"company": "Wannabe Adventure Creative",
		"product_service": "Photography + Videography",
		"goods_services": "Goods / Services",
		"location": "Denver, CO",
		"primary_means_of_sales": "Web, Instagram",
		"website": "https://haackwill95.wixsite.com/whphotography",
		"instagram": "https://www.instagram.com/will_haack/",
		"instagram_tag": "will_haack",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": "LinkTree",
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 17,
		"name": "Alex Hussein",
		"company": "Alex H Media",
		"product_service": "Photography + Videography",
		"goods_services": "Service",
		"location": "Washington DC",
		"primary_means_of_sales": "Web, Instagram",
		"website": "https://alexhmedia.com/",
		"instagram": "https://www.instagram.com/alexhmedia/",
		"instagram_tag": "alexhmedia",
		"active": "?",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 18,
		"name": "Alex Hussein",
		"company": "DC Spot",
		"product_service": "Brand Content Creation",
		"goods_services": "Service",
		"location": "Washington DC",
		"primary_means_of_sales": "Web, Instagram",
		"website": null,
		"instagram": "https://www.instagram.com/dcspot/?hl=en",
		"instagram_tag": "dcspot",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": "http://dcspot.com/links/",
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Showing off the best spot in and around the Washington DC area.",
		"description": null
	},
	{
		"id": 19,
		"name": "Cassandra Czyzewski",
		"company": "CZ Balloons",
		"product_service": "Balloon Designer",
		"goods_services": "Service",
		"location": "New Jersey",
		"primary_means_of_sales": "Web, Instagram",
		"website": null,
		"instagram": "https://www.instagram.com/czballoons/",
		"instagram_tag": "czballoons",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 20,
		"name": "Jay",
		"company": "Dauntless Snacks",
		"product_service": "Food + Drinks",
		"goods_services": "Goods",
		"location": "Denver, CO",
		"primary_means_of_sales": "Web, Instagram, Store",
		"website": "https://dauntlesssnacks.com/",
		"instagram": "https://www.instagram.com/dauntless_snacks/",
		"instagram_tag": "dauntless_snacks",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Snacks for every adventure!",
		"description": "Whether youre backpacking in the mountains or streaming your latest quest, Dauntless Snacks has you covered. Enjoy delicious, protein-packed grass fed beef, delivered straight to your door.\r"
	},
	{
		"id": 21,
		"name": "Austin Wrinkle",
		"company": "WW Ironworks Studio",
		"product_service": "Iron Fabrication + Installation",
		"goods_services": "Goods / Services",
		"location": "Kingsland, TX",
		"primary_means_of_sales": "Web, Instagram, Store",
		"website": "https://www.wwironworksstudio.com/",
		"instagram": "https://www.instagram.com/ww_ironworks_studio/",
		"instagram_tag": "ww_ironworks_studio",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Two generations of metal craftsman...Serving the  greater Austin area. ",
		"description": "Gary, Austin and Cameron Wrinkle are father and son partners in the family owned business.  Explore our collection of portfolios covering interior metalwork, exterior metalwork and our unique custom made metalwork\r"
	},
	{
		"id": 22,
		"name": "Sam LeFebre",
		"company": "Parc Bag",
		"product_service": "Cycling Bag",
		"goods_services": "Goods",
		"location": "Austin, TX",
		"primary_means_of_sales": "Web, Instagram",
		"website": "https://rideparc.com/?",
		"instagram": "https://www.instagram.com/rideparc/",
		"instagram_tag": "rideparc",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": "https://linktr.ee/RideParc",
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "The only cycling gear bag for home and travel",
		"description": "Parc is a new cycling brand dreamed up and built in Austin, TX. Were obsessed cyclists who are constantly planning our next ride. We ride on dirt, gravel, roads and the track. Were just as likely to be pinning on a number to race a flat crit as we are to be headed to the mountains for epic alpine singletrack. Its our belief that if everyone spent some regular time on their bike, the world would be a better place. Parcs mission is to help more people enjoy more time on two wheels. Parcs first product, The Bag, is just us scratching our own itch! We have never found a gear bag that met all our needs and kept us organized at home and on the road. The Bag wont be our only product, but we do expect to produce multiple versions. We also plan to design other innovative products of our own as we expand the brand. Do we know yet what those products will be? Nope! Were going to let our customers tell us what they want and go from there. The Bag launched on Kickstarter in April of 2021 and we raised over 310% of our goal amount to produce our first round of Bags. Thank you to all the Backers who helped make our visions come to life!\r"
	},
	{
		"id": 23,
		"name": "Lorin Ball",
		"company": "Flow Vault",
		"product_service": "Parkour Gym",
		"goods_services": "Service",
		"location": "Denver, CO",
		"primary_means_of_sales": "Web, Instagram, Store",
		"website": "https://www.flowvault.net/",
		"instagram": "https://www.instagram.com/lorinball/",
		"instagram_tag": "lorinball",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Parkour gym in Northglenn, CO. ",
		"description": "My name is Lorin Ball and Im a Colorado gym owner, Parkour Coach, NBCs American Ninja Warrior record holder, and film and photo advertising enthusiast. I was inspired at a young age by my childhood hero Jackie Chan. His hard work and dedication to his hobbies and other interests earned him an amazing career in the film industry. As such Ive managed to do the same with my physical achievements by owning and operating a Parkour & Ninja Warrior training facility.\r"
	},
	{
		"id": 24,
		"name": "Brian Ogle",
		"company": "Elite Era",
		"product_service": "Apparel",
		"goods_services": "Goods",
		"location": "Virginia",
		"primary_means_of_sales": "Web, Instagram",
		"website": "https://www.eliteeraofficial.com/",
		"instagram": "https://www.instagram.com/eliteeraofficial/",
		"instagram_tag": "eliteeraofficial",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "THE NEW ERA OF ELITE ATHLETES",
		"description": "Our name represents a desire to be great and the tattered marks on our logo displays the underdog battle it takes to get there. Built to improve the lives of athletes at all levels and arenas, we know you have it in you and its our mission to get you to the next level. ​We give our word to strive for the highest level of quality in everything that we do. This Brand is a family and we take pride in showing you that no matter the circumstance, this is a new Era, and were here whatever the cost, to make it Elite. Strive to be Elite, well be your biggest fan along the way. \r"
	},
	{
		"id": 25,
		"name": "Ramie Czyzewski",
		"company": "Drop of Golden Sun",
		"product_service": "Photography",
		"goods_services": "Service",
		"location": "Raleigh, North Carolina",
		"primary_means_of_sales": "Word of Mouth",
		"website": "https://dropofgoldensun.photo/",
		"instagram": "https://www.instagram.com/dropofgoldensun/",
		"instagram_tag": "dropofgoldensun",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": "https://bio.site/dropofgoldensun",
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "IM RAMIE! YOUR RALEIGH COUPLES, MATERNITY, AND FAMILY PHOTOGRAPHER",
		"description": "Hi, friend, Im so glad youre here! Im a natural light photographer specializing in lifestyle photography in Raleigh, NC and its surrounding areas. While I do like me a nice look over and say cheese photo, nothing speaks more to me than capturing the love and bond between you and your loved ones. Thats why I focus my photography sessions with bringing out the connection with one another. \r"
	},
	{
		"id": 26,
		"name": "Ramie Czyzewski",
		"company": "Ethereal Boudoir",
		"product_service": "Photography",
		"goods_services": "Service",
		"location": "Raleigh, North Carolina",
		"primary_means_of_sales": "Word of Mouth",
		"website": "https://ethereal.photo/",
		"instagram": "https://www.instagram.com/ethereal.boudoir/",
		"instagram_tag": "ethereal.boudoir",
		"active": "?",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": "https://bio.site/ethereal",
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "ENCAPSULATING INTIMATE MOMENTS AND THE ETHEREAL BEAUTY OF YOUR BODY THROUGH BOUDOIR PHOTOGRAPHY. ",
		"description": "ART IS SUBJECTIVE...  ..AND SO IS THE BEAUTY OF THE HUMAN FIGURE.There is no right or wrong way of being. There is just being, and seeing the beauty you already have. Beauty standards have been redefined here, and it simply is: Embracing who you are. Showing your story. Being true to yourself. Its time we change the way we see beauty in our bodies; each curve, line, and mark all tell the story of you. Lets show it through timeless, intimate photograp\r"
	},
	{
		"id": 27,
		"name": "Peter Bixby",
		"company": "Chapman-Bixby Residential Group",
		"product_service": "Real Estate",
		"goods_services": "Service",
		"location": "Virginia",
		"primary_means_of_sales": "Word of Mouth",
		"website": "https://chapmanbixby.com/",
		"instagram": "https://www.instagram.com/chapmanbixby/",
		"instagram_tag": "chapmanbixby",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": "https://linkin.bio/chapmanbixby",
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "A TRUSTED RESOURCE FOR ANYONE LOOKING TO BUY, SELL OR INVEST IN THE D.C. REGION",
		"description": "Founded in 2018 by two top-producing agents in the D.C. region, the Chapman-Bixby Residential Group set out to become far more than the standard real estate team. With a focus on ownership, innovation, and integrity, Joshua Chapman and Peter Bixby have started this team with a simple but critical mission in mind: to provide our clients with a level of professionalism, customer service, and expertise that is truly second to none.\r"
	},
	{
		"id": 28,
		"name": "Henry Sengle",
		"company": "Thuja",
		"product_service": "Apparel",
		"goods_services": "Goods",
		"location": "Vermont",
		"primary_means_of_sales": "Word of Mouth",
		"website": "https://thujavt.com/",
		"instagram": null,
		"instagram_tag": "",
		"active": null,
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Outdoor Clothing Handmade in Vermont",
		"description": "“Thuja” comes from the botanical name for the Northern White Cedar (Thuja Occidentalis) We create hoodies (sweatshirts). \r"
	},
	{
		"id": 29,
		"name": "Al Bergeron",
		"company": "Scribe Clothing Co",
		"product_service": "Apparel",
		"goods_services": "Goods",
		"location": "Massachusetts",
		"primary_means_of_sales": "Web, Facebook",
		"website": "https://scribeclothing.com/?fbclid=IwAR3x2GdLfUhG66y0XqdWisR7k-pjBhEh08gDL6eD4F3kIxOih9mVCQ0MFFU",
		"instagram": null,
		"instagram_tag": "",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": "https://www.facebook.com/scribeclothingco",
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Tattoo-Inspired Designs With a Story to Tell!",
		"description": "STORIES OF A RECOVERING ALCOHOLIC AND ADDICT My name is Al B. I’m an alcoholic and addict, these are my designs and my stories. I founded Scribe because I’ve always been intrigued by what people choose for their tattoos and the stories behind them. My own tattoos remind me of people I love and they also remind me of my past and how I’ve changed which inspires me to be a better person. Like the Scribes of biblical times, we are storytellers. The stories of our past and how we have changed can benefit others. That’s why at Scribe, all our tattoo-inspired designs have backstories about living a sober, spiritual and tattoo lifestyle.\r"
	},
	{
		"id": 30,
		"name": "Erika Curran Yee (Schaaf)",
		"company": "Commissioned artist",
		"product_service": "Art/Painting",
		"goods_services": "Goods",
		"location": "Virginia",
		"primary_means_of_sales": "Instagram",
		"website": null,
		"instagram": null,
		"instagram_tag": "",
		"active": null,
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 31,
		"name": "Jan Bruso",
		"company": "Retro Art by Jan",
		"product_service": "Art/Painting",
		"goods_services": "Goods",
		"location": "Sedona, Arizona",
		"primary_means_of_sales": "Web,Facebook, Instagram",
		"website": "https://retroartbyjan.com",
		"instagram": "thefunkysidestudio",
		"instagram_tag": "thefunkysidestudio",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 32,
		"name": "Cindy Beauregard Sanchez",
		"company": "Art by Macakipa",
		"product_service": "Art/Painting",
		"goods_services": "Goods",
		"location": "Massachusetts",
		"primary_means_of_sales": "Etsy,Facebook,Instagram",
		"website": "https://www.etsy.com/shop/ArtbyMacakipa?fbclid=IwAR1RtweJbM8eqxsy9_KSPCYwWGxD-M5Xmn4pZr-aXHkTauIjx5Rf78JZHl4",
		"instagram": "artbymacakipa",
		"instagram_tag": "artbymacakipa",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Original Artwork by Cindy Sanchez",
		"description": "Welcome to Art by Macakipa!\rAll artwork is created by Cindy Sanchez, a multimedia artist.\rAll artwork is original from sketch to completion.\r All artwork is original from sketch to completion.\r High quality, gallery and professional quality materials used for each piece.\r Pricing is based on materials used and time to create the piece.\r * All proceeds from my heart paintings are given to the Ollie Hinkle Heart Foundation. Learn more about this wonderful foundation at https://www.theohhf.org/\r"
	},
	{
		"id": 33,
		"name": "Emily Poulin",
		"company": "Sew All the Ruffles",
		"product_service": "Baby Accessories",
		"goods_services": "Goods",
		"location": "Virginia",
		"primary_means_of_sales": "Etsy, Facebook,Instagram",
		"website": "https://www.etsy.com/shop/SewAllTheRuffles?fbclid=IwAR0suwnQzataqdd1XtkngW9dXvv4GmPXz_sWOmA9_w_uKM6TM0sxHlSEtY8",
		"instagram": "sewalltheruffles",
		"instagram_tag": "sewalltheruffles",
		"active": "Y",
		"who_shared_it": null,
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Custom nursery accessories for your sweet littles",
		"description": null
	},
	{
		"id": 34,
		"name": "Ari Horowitz",
		"company": "Sleek Life Design",
		"product_service": "Accessories",
		"goods_services": "Goods",
		"location": "New York",
		"primary_means_of_sales": "Web, Word of Mouth",
		"website": "https://www.sleeklifedesign.com/collections/shop",
		"instagram": "https://www.instagram.com/sleeklifedesign/",
		"instagram_tag": "sleeklifedesign",
		"active": null,
		"who_shared_it": "Jesse Isserow",
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Fidget toys + sleek wallets",
		"description": "We exist to make better designed, crafted, and thought out versions of the basics. Founded by a group of entrepreneurs and product designers whose minds dont stop moving. We launched with our first project, Vessel Wallet, on Kickstarter and after a successful campaign evolved into a small company.\r"
	},
	{
		"id": 35,
		"name": "Dominic Purpura",
		"company": "Mela Watermelon Water",
		"product_service": "Food + Drinks",
		"goods_services": "Goods",
		"location": "California",
		"primary_means_of_sales": "Web, Instagram, Facebook",
		"website": "https://melawater.com/",
		"instagram": "https://www.instagram.com/mela/",
		"instagram_tag": "mela",
		"active": null,
		"who_shared_it": "Luke Chapman",
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Watermelon water providing an elite way to capture numerous health benefits",
		"description": "Transport yourself to Vietnams deep, authentic jungle, a climate that produces the worlds most perfect ripe watermelons. Straight from the juicy red cores to the can, Mela is served as a slice of paradise with every sip! Watermelons are an elite way to hydrate by providing natural electrolytes, antioxidants, and numerous other health benefits. Curbing cravings with natural sugar, Mela, will leave you feeling replenished and ready for your next adventure.\r"
	},
	{
		"id": 36,
		"name": "Need A Name",
		"company": "Nextmug by Nextboom",
		"product_service": "Housewares",
		"goods_services": "Goods",
		"location": "Boulder, Colorado",
		"primary_means_of_sales": "Web, Word of Mouth",
		"website": "https://www.amazon.com/Nextmug-Temperature-Controlled-Self-Heating-Coffee-Mug-Black/dp/B0B6JTX6NB?th=1",
		"instagram": null,
		"instagram_tag": "",
		"active": null,
		"who_shared_it": "Sara Mahan",
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Temperature-Controlled, Self-Heating Coffee Mug",
		"description": "Small business is Bolder, CO creating the next best mug for your collection. Never drink cold coffee or tea again as Nextmug keeps preferred beverage at the perfect temperature wherever you are.\r"
	},
	{
		"id": 37,
		"name": "Michelle Maher",
		"company": "Kindred Crafts",
		"product_service": "Crafts",
		"goods_services": "Goods",
		"location": "Virginia",
		"primary_means_of_sales": "In Person, Instagram",
		"website": "https://www.etsy.com/shop/kindredcraftsmarket/",
		"instagram": "https://www.instagram.com/kindred.crafts/",
		"instagram_tag": "kindred.crafts",
		"active": null,
		"who_shared_it": "Laura Townsley",
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "simple handmade items for you & your home",
		"description": "\\\\ Kindred Crafts products are all handmade, reminding me of a simpler time :) My great grandma taught me how to knit when I was in 4th grade, and ever since then, I have loved to knit, sew, and dream up new creative outlets for my latest crafting endeavors! My heart leaps at the chance to create something beautiful out of unsuspecting resources and breathe new life into their potential! It brings me such joy to make these products for you & your home, and I hope they can bring the same joy to you!\r"
	},
	{
		"id": 38,
		"name": "Hannah Miko",
		"company": "Beloved Blooms ",
		"product_service": "Crafts",
		"goods_services": "Goods",
		"location": "Virginia",
		"primary_means_of_sales": "Web, Instagram, Facebook",
		"website": "https://www.belovedbloomsdesigns.com/",
		"instagram": "https://www.instagram.com/belovedbloomsdesigns/",
		"instagram_tag": "belovedbloomsdesigns",
		"active": null,
		"who_shared_it": "Laura Townsley",
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "UNIQUE FLOWER PRESERVATION",
		"description": "Beloved Blooms Designs is owned by Hannah Miko Arczynski. She has always been in awe and inspired by the nature around her and loves the opportunity to display beautiful flowers that will last for years.  Her style is simplistic with a love for earth tones and pops of color.  She loves the opportunity to capture special moments for a couple or family through flower preservation.  \r"
	},
	{
		"id": 39,
		"name": "Ali",
		"company": "Covenant Threads Embroidery",
		"product_service": "Crafts",
		"goods_services": "Goods",
		"location": "South Carolina",
		"primary_means_of_sales": "Web, Instagram, Facebook",
		"website": null,
		"instagram": "https://www.instagram.com/covenant.threads/",
		"instagram_tag": "covenant.threads",
		"active": null,
		"who_shared_it": "Laura Townsley",
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 40,
		"name": "Denise Godfrey",
		"company": "A Floral Event",
		"product_service": "Wedding + Event Florist",
		"goods_services": "Service",
		"location": "Virginia",
		"primary_means_of_sales": "Web, Instagram, Facebook",
		"website": null,
		"instagram": "https://www.instagram.com/afloraleventva/",
		"instagram_tag": "afloraleventva",
		"active": null,
		"who_shared_it": "Laura Townsley",
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 41,
		"name": "Kelly",
		"company": "Making Minnie Memories",
		"product_service": "Gifts",
		"goods_services": "Goods",
		"location": "Florida",
		"primary_means_of_sales": "Web, Instagram, Facebook",
		"website": "https://www.etsy.com/shop/MakingMinnieMemories",
		"instagram": "https://www.instagram.com/makingminniememories/",
		"instagram_tag": "makingminniememories",
		"active": null,
		"who_shared_it": "Laura Townsley",
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Minnie Ears to make your Disney Day even more Magical!",
		"description": null
	},
	{
		"id": 42,
		"name": "Carol and Joseph",
		"company": "Sewn and Thrown",
		"product_service": "Pottery and Sewn Goods",
		"goods_services": "Goods",
		"location": "Virginia",
		"primary_means_of_sales": "Web, Instagram, Facebook",
		"website": "https://www.sewnandthrown.com/gallery",
		"instagram": "https://www.instagram.com/sewnandthrown/",
		"instagram_tag": "sewnandthrown",
		"active": null,
		"who_shared_it": "Laura Townsley",
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Husband + wife team mindfully making handmade pottery • quilts • aprons • more!",
		"description": "This is us, Carol + Joseph Menkis. We are a husband and wife team creating and sharing our work from our tiny apartment in the greater DC area. We both attended Virginia Tech, where Joseph studied Architectural Design and Carol studied Human Development. It was in the mountains of Southwest Virginia where both our love for pottery and sewing were born. Creating is something we set out to weave within the fibers of our family unit. We hope that this is not only a place where you can receive quality handmade goods but also one that can inspire you to do the same.\r"
	},
	{
		"id": 43,
		"name": "Emily Speake",
		"company": "Mabel Goods",
		"product_service": "Accessories",
		"goods_services": "Goods",
		"location": "Florida",
		"primary_means_of_sales": "Web, Instagram, Facebook",
		"website": "https://www.etsy.com/shop/MabelGoodsShop",
		"instagram": "https://www.instagram.com/mabelgoods/",
		"instagram_tag": "mabelgoods",
		"active": null,
		"who_shared_it": "Laura Townsley",
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Handmade polymer clay accessories and decor",
		"description": "Its no surprise I LOVE to create! Making these little clay pieces has brought me great joy, and Im thrilled to share my joy with you.\r"
	},
	{
		"id": 44,
		"name": "Kaitlyn Phipps",
		"company": "Kaitlyn Phipps Photography",
		"product_service": "Photography",
		"goods_services": "Service",
		"location": "Virginia",
		"primary_means_of_sales": "Web, Instagram, Facebook",
		"website": "https://kaitlynphippsphotography.com/",
		"instagram": "https://www.instagram.com/kaitlynphippsphoto/",
		"instagram_tag": "kaitlynphippsphoto",
		"active": null,
		"who_shared_it": "Laura Townsley",
		"has_logo": null,
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 45,
		"name": "Need A Name",
		"company": "Team Self Love Collection",
		"product_service": "Apparel",
		"goods_services": "Goods",
		"location": "Colorado",
		"primary_means_of_sales": "Web, Instagram, Facebook",
		"website": "https://tslcollection.com/password",
		"instagram": "https://www.instagram.com/tslcollection/",
		"instagram_tag": "tslcollection",
		"active": null,
		"who_shared_it": "Ashley Pearson",
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": null,
		"description": null
	},
	{
		"id": 46,
		"name": "Mikhaila",
		"company": "Elara Fine Jewelry",
		"product_service": "Jewelry",
		"goods_services": "Goods",
		"location": "Colorado",
		"primary_means_of_sales": "Web, Instagram, Facebook",
		"website": "https://www.etsy.com/shop/ElaraFineJewelry",
		"instagram": "https://www.instagram.com/elarafinejewelry/",
		"instagram_tag": "elarafinejewelry",
		"active": null,
		"who_shared_it": "Tatyana",
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Minimalist Body Jewelry, Septum & Belly Button Rings",
		"description": "As a lover of the dainty jewelry look, I found that it was difficult to find pieces that were both delicate and elegant, but still WOW. Over the years Ive collected jewelry that is unique, that I hadnt seen other people wearing, and Ive loved it. And seeing other peoples reactions to what I was wearing, I knew I couldnt go back to the same old. After seeing the same things over and over, I decided to start my own brand, with pieces specially curated by me, as the lead designer. I pour my love into everything I create and sell. My pieces are designed to accentuate your existing energy and beauty. I hope you love my selection of belly button rings, septum clickers, nipple jewelry, tragus piercings, daith hoop rings, earrings, cartilage piercings, and more! Now offering 14k solid gold options, as well as titanium and 316L grade surgical steel. Minimal, beautiful body jewelry options.\r"
	},
	{
		"id": 47,
		"name": "Zoey",
		"company": "ZSW Prints",
		"product_service": "Custom Designs",
		"goods_services": "Goods",
		"location": "Colorado",
		"primary_means_of_sales": "Web, Instagram, Facebook",
		"website": "https://www.etsy.com/shop/ZSWPrints",
		"instagram": "https://www.instagram.com/zsw_prints/",
		"instagram_tag": "zsw_prints",
		"active": null,
		"who_shared_it": "Tatyana",
		"has_logo": "Y",
		"other_link_1": null,
		"other_link_2": null,
		"other_link_3": null,
		"short_description": "Im a new designer, with a dream of making cool items!",
		"description": "When I started to notice that I could never find specific designs for gifts or even myself, I decided to learn how to design my own work! And here we are, Im starting small with only a few things but hoping to expand to putting my designs on many different items!\r"
	}
]
