import Content from "./Content";
import Navbar from "./Navbar";

export default function Main() {
    return (
        <>
            <Navbar />
            <Content />
        </>
    )
}