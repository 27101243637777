import { Box, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";

interface Props {
    searchValue: string;
    setSearchValue: (newValue: string) => void;
}

export default function Search({searchValue, setSearchValue}: Props) {
    const [localSearchValue, setLocalSearchValue] = useState('');

    function submitSearchValue() {
        setSearchValue(localSearchValue);
    }

    return (
        <Box display='flex' justifyContent='stretch' width='100%'>
            <TextField
                id="outlined-basic" 
                variant="outlined" 
                inputProps={{
                    notched: false,
                }}
                placeholder="Find niche, local, unique"
                fullWidth
                onChange={(event) => setLocalSearchValue(event.target.value)}
                onKeyPress={(event) => {
                    console.log(`Pressed keyCode ${event.key}`);
                    if (event.key === 'Enter') {
                      // Do code here
                      event.preventDefault();
                      submitSearchValue();
                    }
                }}
            />
            <Button 
                variant={searchValue === localSearchValue ? 'outlined' : 'contained'} 
                onClick={() => submitSearchValue()}
                color='warning'
            >
                Search
            </Button>
        </Box>
    )
}