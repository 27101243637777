import { Avatar, Box, Card, CardContent, CardHeader, Chip, Divider, Paper, Typography } from "@mui/material";
import { Company } from "../types";
import PlainLink from "./PlainLink";
import ProductServiceChip from "./ProductServiceChip";
import './CompanyCard.css';
import CompanyIcon from "./CompanyIcon";
import { findBackgroundColor } from "../utils";
import { useCallback, useEffect, useRef, useState } from "react";

interface Props {
    company: Company
}

export default function CompanyCard({company}: Props) {


    // console.log('image path check', `/images/${fileName}_logo.png` ?? `/images/${fileName}_logo.jpg`)

    // const tryRequire = (path: string) => {
    //     try {
    //      return import(`${path}`);
    //     } catch (err) {
    //      return null;
    //     }
    // };

    // const file = tryRequire(`${process.env.PUBLIC_URL}/images/${fileName}_logo.png`) || tryRequire(`${process.env.PUBLIC_URL}/images/${fileName}_logo.jpg`)
    // console.log('file', file);
    // const ImageSource = file;
    const companyName = company.company;
    let imageSource = '';

    if(companyName) {
        const fileUnderscore = companyName.replace(/ /g,"_").replace(/'/g, "");
        const fileNoQuote = fileUnderscore.replace(/'/g, "");
        const fileName = fileNoQuote.toLowerCase();
    
        imageSource = `/images/${fileName}_logo.png`;
    }
    
    const bgColor = findBackgroundColor(company);
    const imageRef = useRef<HTMLDivElement | null>(null);

    const [domNode, setDomNode] = useState<HTMLDivElement | null>(null);

    const onRefChange = useCallback((node: HTMLDivElement | null) => {
        if(node !== null) {
            setDomNode(node); // trigger re-render on changes
        }
    // ...
        // console.log('domNode', stylingRef.current)
    }, []);

    useEffect(() => {
        setDomNode(imageRef.current)
    }, [imageRef]);

    return (
        // <Box width={{md: '75%', sm: '90%', xs: '95%'}} mt={4}>
        <Box ml={2} mt={2} minWidth={{md: '375px', xs: '100%'}} position='relative' className="company-card">
            <PlainLink link={`company/${company.id}`}>
                <Card elevation={5}>
                    <CardHeader 
                        title={company.company} 
                        titleTypographyProps={{
                            fontSize: '20px'
                        }}
                        subheader={
                            <Box display='flex' justifyContent='space-between'>
                                <ProductServiceChip company={company} />
                                <Typography variant='caption'>{company.location}</Typography>
                            </Box>
                        }
                    >
                    </CardHeader>
                    <CardContent>
                        <Box display='flex' justifyContent='center' alignItems='center' mb={2}>
                            {/* <CompanyIcon company={company} /> */}
                            <Avatar
                                src={imageSource}
                                // ref={onRefChange}
                                ref={imageRef}
                                sx={{ height: {md: '128px', xs: 'auto'}, width: {md: 'auto', xs: '128px'}, 'backgroundColor': (domNode?.classList.contains('MuiAvatar-colorDefault') ? `${bgColor}` : '') }}
                                variant="square"
                            >
                                {company.company?.charAt(0)}
                            </Avatar>
                        </Box>
                        <Box display='flex' justifyContent='space-between' mb={1}>
                            <Typography>
                                {company.name}
                            </Typography>
                            <Typography fontWeight='8px'>
                                {company.goods_services}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box mt={1}>
                            <Typography>
                                {company.website}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </PlainLink>
        </Box>
    )
}