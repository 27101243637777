import { Avatar } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { Company } from "../types";
import { findBackgroundColor } from "../utils";

interface Props {
    company: Company;
}
export default function CompanyIcon({company}: Props) {

    const fileUnderscore = company.company.replace(/ /g,"_").replace(/'/g, "");
    const fileNoQuote = fileUnderscore.replace(/'/g, "");
    const fileName = fileNoQuote.toLowerCase();

    const imageSource = `/images/${fileName}_logo.png`;
    
    const bgColor = findBackgroundColor(company);
    const imageRef = useRef<HTMLDivElement | null>(null);
    
    // const stylingRef = useRef<string | null>(null);

    // const [imageStyle, setImageStyle] = useState({});
    // let mystyle = {};

    // const [domNode, setDomNode] = useState<string | null>(null);
    const [domNode, setDomNode] = useState<HTMLDivElement | null>(null);

    const onRefChange = useCallback((node: HTMLDivElement | null) => {
        console.log('node', node)
        if(node !== null) {
            console.log('bgColor', bgColor)
            // const newUpdate = {'backgroundColor': node?.classList.contains('MuiAvatar-colorDefault') ? `${bgColor}` : ''};
            const newUpdate = node.classList.contains('MuiAvatar-colorDefault') ? `${bgColor}` : '';
            console.log('newUpdate', newUpdate)
            // setDomNode(newUpdate); // trigger re-render on changes
            setDomNode(node); // trigger re-render on changes
            // stylingRef.current = newUpdate;
            imageRef.current = node;

        }
    // ...
        // console.log('domNode', stylingRef.current)
    }, []);


    // useEffect(() => {
    //     console.log('imageRef', imageRef)
    //     console.log('stylingRef.current', stylingRef.current)
    // }, []);

    // useEffect(() => {
    //     setDomNode({'backgroundColor': imageRef.current?.classList.contains('MuiAvatar-colorDefault') ? `${bgColor}` : ''}); // trigger re-render on changes
    // }, [imageRef]);

    // useEffect(() => {
    //     if(stylingRef?.current) {
    //         setDomNode(stylingRef.current)
    //     }
    // }, [company]);


    // mystyle = {'backgroundColor': imageRef?.current?.classList.contains('MuiAvatar-colorDefault') ? `${bgColor}` : ''};
    // useEffect(() => {
    //     if(imageRef.current) {
    //         console.log('imageRef', imageRef, imageRef?.current)
    //         console.log('stylingRef', stylingRef, stylingRef?.current)
            
    //         // setImageStyle({'backgroundColor': imageRef?.current?.classList.contains('MuiAvatar-colorDefault') ? `${bgColor}` : ''});
    //         // mystyle = {'backgroundColor': imageRef?.current?.classList.contains('MuiAvatar-colorDefault') ? `${bgColor}` : ''};
    //         stylingRef.current = {'backgroundColor': imageRef.current?.classList.contains('MuiAvatar-colorDefault') ? `${bgColor}` : ''};
    //     }
    
    // }, [company, imageRef]);
    // mystyle = {'backgroundColor': domNode?.classList.contains('MuiAvatar-colorDefault') ? `${bgColor}` : ''};
    // mystyle = domNode;

    // const otherStyles = domNode !== null ? {'backgroundColor': domNode} : {}
    // console.log('otherStyles', otherStyles)


    return (
        <Avatar
            src={imageSource}
            ref={onRefChange}
            // ref={imageRef}
            sx={{ height: {md: '128px', xs: 'auto'}, width: {md: 'auto', xs: '128px'}, 'backgroundColor': (domNode?.classList.contains('MuiAvatar-colorDefault') ? `${bgColor}` : '') }}
            variant="square"
        >
            {company.company.charAt(0)}
        </Avatar>
    )
}