import { Box, List } from "@mui/material";
import { wrap } from "module";
import { Company } from "../types";
import CompanyCard from "./CompanyCard";
import CompanyListItem from "./CompanyListItem";


interface Props {
    companies: Company[];
    wayToViewData: 'grid' | 'list';
}

export default function Results({companies, wayToViewData}: Props) {

    if (wayToViewData === 'grid') {
        return (
            <Box display='flex' justifyContent='center' flexDirection='row' width='100%' flexWrap='wrap'>
                {companies.map(company => {
                    return (
                        <CompanyCard company={company} key={`${company.name}${company.company}`} />
                    )
                })}
            </Box>
        )
    } else {
        return (
            <Box width={{md: '75%', xs: '100%'}} display='block' mx='auto' >
                <List>
                    {companies.map(company => {
                        return (
                            <CompanyListItem company={company} key={`${company.name}${company.company}`} />
                        )
                    })}
                </List>
            </Box>
        )
    }
}