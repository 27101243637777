import { Box, Chip, Divider, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import config from "../config";
import { Company } from "../types";
import CompanyIcon from "./CompanyIcon";
import ProductServiceChip from "./ProductServiceChip";
import './CompanyListItem.css';

interface Props {
    company: Company
}

export default function CompanyListItem({company}: Props) {

    return (
        <Box width={{xs: '100%'}}>
            <ListItem divider className='company-list-item' disablePadding={true}>
                <ListItemText
                    primary={
                        <Box display='flex' flexDirection='column'>
                            <Typography variant='h5'>{company.company}</Typography>
                            <Box display='flex' justifyContent='space-between'>
                                <ProductServiceChip company={company} />
                                <Typography variant='caption'>{company.location}</Typography>
                            </Box>
                        </Box>
                        
                    }
                    secondary={
                        <Box display='block'>
                            <Box display='flex' justifyContent='flex-start' alignItems='center' my={2}>
                                <CompanyIcon company={company} />
                            </Box>
                            <Box display='flex' justifyContent='space-between' my={1}>
                                <Typography>
                                    {company.name}
                                </Typography>
                                <Typography fontWeight='8px'>
                                    {company.goods_services}
                                </Typography>
                            </Box>
                            {/* <Divider /> */}
                            <Box mt={1} width='100%' display='flex' flexBasis='auto' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
                                <Typography textOverflow="ellipsis" whiteSpace='nowrap' width='100%' overflow='hidden'>
                                    {company.website}
                                </Typography>
                            </Box>
                        </Box>
                    }
                >
                </ListItemText>
            </ListItem>
        </Box>
    )
}