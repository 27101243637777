import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import config from "../config";
import { GoodOrService } from "../types";

interface Props {
    goodOrService: GoodOrService;
    typeOfGoods: string;
    setGoodOrService: (goodOrService: GoodOrService) => void;
    setTypeOfGoods: (typeOfGoods: string) => void;
}

export default function Filter({goodOrService, typeOfGoods, setGoodOrService, setTypeOfGoods}: Props) {

    const goods = config.goods;

    function handleChange(event: SelectChangeEvent<any>) {
        console.log('event', event)
        setGoodOrService(event.target.value)
    }

    return (
        <Grid container mt={4}>
            <Grid item md={2} sm={4} xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Goods / Services</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={goodOrService}
                        label="Goods / Services"
                        onChange={handleChange}
                    >
                        <MenuItem value='both'>Goods and Services</MenuItem>
                        <MenuItem value={'goods'}>Goods</MenuItem>
                        <MenuItem value={'service'}>Service</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={2} sm={4} xs={12} ml={{md: 2, xs: 0}}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type of Goods</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={typeOfGoods}
                        label="Type of goods"
                        onChange={(event) => setTypeOfGoods(event.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {goods.map(good => {
                            return (
                                <MenuItem value={good.name}>{good.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}