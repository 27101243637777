import { Config } from "./types";

const config: Config = {
    "goods": [
        {
            'name': 'Accessories',
            'color': '#D7BDE2'
        },
        {
            'name': 'Apparel',
            'color': '#8B008B'
        },
        { 
            'name': 'Art/Painting',
            'color': '#4169E1'
        },
        {
            'name': 'Baby Accessories',
            'color': '#40E0D0'
        },
        {
            'name': 'Balloon Designer',
            'color': '#30EE90'
        },
        {
            'name': 'Beef Sticks',
            'color': '#DAA520'
        },
        {
            'name': 'Brand Content Creation',
            'color': '#DB7093'
        },
        {
            'name': 'Crafts',
            'color': '#9FE2BF'
        },
        {
            'name': 'Custom Designs',
            'color': '#F4D03F'
        },
        {
            'name': 'Custom Stained Glass / other',
            'color': '#008B8B'
        },
        {
            'name': 'Cycling Bag',
            'color': '#FF6347'
        },
        {
            'name': 'Dog sitting',
            'color': '#6B8E23'
        },
        {
            'name': 'Dune Buggy Rentals',
            'color': '#FFDAB9'
        },
        {
            'name': 'Fitness Coach',
            'color': '#A0522D'
        },
        {
            'name': 'Food + Drinks',
            'color': '#21978B'
        },
        {
            'name': 'Gifts',
            'color': '#8F2731'
        },
        {
            'name': 'Home Vacation Rental',
            'color': '#6495ED'
        },
        {
            'name': 'Housewares',
            'color': '#33F4E8'
        },
        {
            'name': 'Iron Fabrication + Installation',
            'color': '#FFD700'
        },
        {
            'name': 'Jewelry',
            'color': '#F0FFFF'
        },
        {
            'name': 'Music Producer',
            'color': '#DDA0DD'
        },
        {
            'name': 'Parkour Gym',
            'color': '#FF0000'
        },
        {
            'name': 'Payment Platform',
            'color': '#008000'
        },
        {
            'name': 'Photography',
            'color': '#FF69B4'
        },
        {
            'name': 'Photography + Videography',
            'color': '#2E8B57'
        },
        {
            'name': 'Portrait Photography',
            'color': '#BDB76B'
        },
        {
            'name': 'Pottery and Sewn Goods',
            'color': '#F4DD33'
        },
        {
            'name': 'Real Estate',
            'color': '#800000'
        },
        {
            'name': 'Unique Pottery',
            'color': '#FF00FF'

        },
        {
            'name': 'Videography',
            'color': '#FFA500'
        },
        {
            'name': 'Wedding + Event Florist',
            'color': '#5791E4'
        }
    ]
}

export default config;