import { Chip } from "@mui/material";
import config from "../config";
import { Company } from "../types";
import { findBackgroundColor } from "../utils";

interface Props {
    company: Company;
}

export default function ProductServiceChip({company}: Props) {

    const bgColor = findBackgroundColor(company);
    const textColor = Number(bgColor.charAt(1)) >= 8 ? 'white' : 'black';

    return (
        <Chip 
            size="small"
            label={company.product_service ?? 'N/A'} 
            sx={{
                backgroundColor: `${bgColor}`,
                color: `${textColor}`
            }} 
        />
    )
}